import $ from 'jquery'
import './lib/canvasDots/canvasdots';
import logo from './assets/logo-improved.png';
import logoData from './assets/canvasPoint/logo';
import _ from 'lodash';

const canvasOptions = {
    speed: 1,
    sizeDependConnections: false,
    dotsColor: [255, 255, 255, 0.2],
    linesColor: [44, 96, 219],
    randomBounceSides: false,
    fillCircles: true,
    magnetPowerDelimiter: 50
};

$(document).ready(() => {
    ((enabled) => {
        if (!enabled)
            return;

        const canvasDom = $('#alex-canvas');
        const canvas = canvasDom.canvaDots(canvasOptions);
        const canvasVanilla = canvasDom[0];
        logoData.forEach((val, i) => {
            logoData[i] = {x: val.x * 1.5, y: val.y * 2}
        });
        canvas.loadSpecificArray(1, logoData);
        canvas.setSpecificArrayOffsetX(window.innerWidth - 600);
        canvas.setSpecificArrayOffsetY(100);
        canvas.setSpecificArray(1);
        canvasVanilla.height = window.innerHeight;
        canvasVanilla.width = window.innerWidth;
        window.addEventListener("resize", _.debounce(() => {
            canvasVanilla.height = window.innerHeight;
            canvasVanilla.width = window.innerWidth;
            canvas.setSpecificArrayOffsetX(window.innerWidth - 600);
        }, 200, {trailing: true, leading: false}));
        $('#apply').on('click', () => {
            const lineColor = $('#lineColor').val().split(',').map(i => Number(i));
            const fillColor = $('#circleColor').val().split(',').map(i => Number(i));
            const bgColor = $('#bgColor').val();

            console.log(lineColor);
            canvasDom.css('background', bgColor);
            canvas.setLinesColor(lineColor);
            canvas.setDotsColor(fillColor);
        });
    })(true);

    ((enabled) => {
        if (!enabled)
            return;

        const canvasDom = $('#data-canvas');
        const context = canvasDom[0].getContext('2d');
        const logoImage = new Image;
        logoImage.src = logo;
        logoImage.onload = () => {
            context.drawImage(logoImage, 0, 0, 256, 256);
            const data = [];
            _.range(0, 256).forEach(x => {
                _.range(0, 256).forEach(y => {
                    const [r, g, b, a] = context.getImageData(x, y, 1, 1).data;
                    const condition = [g, b].every(i => i === 0) && r > 200;
                    if (condition)
                        data.push({x, y});
                });
            });
            console.log(data.filter((a, i) => i % 2 === 0));
        }
    })(false);
});
